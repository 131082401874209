import {
  Button,
  FormGroup,
  Grid,
  OutlinedInput,
  Paper,
  Typography,
  useTheme,
} from "@mui/material";
import { useForm } from "react-hook-form";

export const Form = ({
  title,
  titleStyles,
}: {
  title?: string;
  titleStyles?: any;
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const onSubmit = (data: any) => console.log(data);
  const { palette } = useTheme();
  return (
    <Paper
      sx={{
        padding: "30px",
        width: 600,
        backgroundColor: "transparent",
        boxShadow: "none",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Typography variant="h4" paddingBottom="10px" sx={titleStyles}>
        {title}
      </Typography>
      <FormGroup>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <OutlinedInput
              type="address"
              id="address-input"
              placeholder="Address"
              sx={{ width: "100%", backgroundColor: palette.secondary.light }}
              {...register("address", { required: true, minLength: 6 })}
            />
          </Grid>
          <Grid item xs={6}>
            <OutlinedInput
              type="text"
              id="first-name-input"
              placeholder="First Name"
              sx={{ width: "100%", backgroundColor: palette.secondary.light }}
              {...register("name", { required: true, maxLength: 80 })}
            />
          </Grid>
          <Grid item xs={6}>
            <OutlinedInput
              type="text"
              id="last-name-input"
              placeholder="Last Name"
              sx={{ width: "100%", backgroundColor: palette.secondary.light }}
              {...register("name", { required: true, maxLength: 80 })}
            />
          </Grid>
          <Grid item xs={12}>
            <OutlinedInput
              type="tel"
              id="telephone-input"
              placeholder="Mobile Number"
              sx={{ width: "100%", backgroundColor: palette.secondary.light }}
              {...register("mobileNumber", {
                required: true,
                minLength: 6,
                maxLength: 12,
              })}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              type="submit"
              variant="contained"
              onClick={handleSubmit(onSubmit)}
              sx={{
                width: "100%",
                height: "50px",
                color: palette.secondary.light,
              }}
              color="secondary"
            >
              Get My Fair Offer
            </Button>
          </Grid>
        </Grid>
      </FormGroup>
    </Paper>
  );
};
