import React from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Container,
  Box,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export const FAQ = () => {
  const faqData = [
    {
      question: "1. How can I get a fair offer?",
      answer:
        "Looking to get an offer from Gulfview Holdings? It's simple! Securing an offer is simple. You can fill out our straightforward form by clicking on our Contact Uspage, or you can call us at (727) 304-3768. Gulfview Holdings presents “as-is” offers – meaning you won’t need to make any repairs. We purchase properties in various conditions, from ugly houses to turnkey homes, and in any price range, situation, or circumstance. As we're not real estate agents, there are no realtor commissions to worry about. Our offers are always prompt, equitable, hassle-free, and come with no obligations. To discover what we can offer you, visit our “Contact Us” page!",
    },
    {
      question: "2. How do you determine the price to offer on my house?",
      answer:
        "Gulfview Holdings offers a straightforward “3 Step Home Buying Process” for purchasing homes. To begin, simply call us or complete the form on our Contact Us page. We'll gather some basic information about the address and property conditions to formulate our offer. Our pricing is based on neighborhood comp values and the condition of the house. Next, we present our hassle-free, no-obligation, and fair offer. Finally, we can close quickly at your convenience. There are no yard signs, realtor commissions, open houses, showings, or internet listings to worry about. Just a quick and easy home sale!",
    },
    {
      question: "3. Are there any fees or commissions to work with you?",
      answer:
        "What sets us apart from the traditional method of selling your house is simple: there are absolutely NO fees or commissions when you sell your house to us. We'll make you an offer, and if it's suitable, we'll buy your house (and we often cover the closing costs too!). There's no hassle, no fees. We earn our profit after we pay for any necessary repairs on the house and sell it for a profit. We take all the risk here - once we buy the house from you, the responsibility is ours. You can walk away without the burden of the property and its payments, often with cash in hand.",
    },
    {
      question:
        "4. What's the difference between selling to you or with an agent?",
      answer:
        "Real estate agents typically list properties and wait for a buyer. They show the properties to potential buyers (though in many markets, the average time to sell a property can be over 6 months) and then take a percentage of the sale price if a buyer is found. This commission usually ranges from 3-6% of the sale price (so for a $500,000 house, you'd pay between $15,000 and $30,000 in commissions). While agents offer a valuable service for those willing to wait and pay commissions, we operate differently: we're not agents, but rather home buyers. Our company buys houses directly. Because we're the ones purchasing your house we can decide to buy your house quickly.",
    },
    {
      question:
        "5. What's the difference between selling to you or with an agent?",
      answer:
        "Selling to Gulfview Holdings offers numerous benefits. Unlike buyers you might encounter while listing with a real estate agent, we can close quickly. Once a clear title is demonstrated, we're prepared to close. Moving on from a property is a significant decision. You don’t want to sell to just anyone, as it could result in the buyer backing out, wasting your time and money on ongoing taxes, maintenance, utilities, and insurance. Worse, you could fall victim to a scam. You can reach us at any time and speak with a live person. We're committed to this business for the long term and understand that reputation matters more than any single deal.",
    },
  ];

  return (
    <Box>
      <Container sx={{ padding: 5 }} maxWidth="md">
        <Typography variant="h5" gutterBottom>
          Frequently Asked Questions
        </Typography>
        {faqData.map((faq, index) => (
          <Accordion key={index}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`panel${index}-content`}
              id={`panel${index}-header`}
            >
              <Typography>{faq.question}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>{faq.answer}</Typography>
            </AccordionDetails>
          </Accordion>
        ))}
      </Container>
    </Box>
  );
};
