import { createTheme } from "@mui/material";


declare module '@mui/material/styles' {
    interface Theme {
      status: {
        danger: string;
      };
    }
    
    interface ThemeOptions {
      status?: {
        danger?: string;
      };
    }
  }

export const theme = createTheme({
    palette: {
        primary: {
          light: '#9FA6AD',
          main: '#32383E',
          dark: '#171A1C',
          contrastText: '#F0F4F8',
        },
        secondary: {
          light: '#FAF9F6',
          main: '#2979ff',
          dark: '#1c54b2',
          contrastText: '#000',
        },
      },
      typography: {
        button: {
          textTransform: 'none'
        }
      }
  });