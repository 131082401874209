import React from "react";
import { Container, Grid, Typography, Link, useTheme } from "@mui/material";
const footerStyles = {
  color: "#ffffff",
  padding: "48px 0",
};

const footerTextStyles = {
  fontSize: "16px",
  lineHeight: "24px",
};
const footerSection = {
  fontSize: "24px",
  lineHeight: "24px",
  fontWeight: 600,
};
const footerLinkStyles = {
  color: "#ffffff",
  textDecoration: "none",
  "&:hover": {
    textDecoration: "underline",
  },
};

const Footer = () => {
  const { palette } = useTheme();

  return (
    <footer style={{ ...footerStyles, backgroundColor: palette.primary.main }}>
      <Container maxWidth="lg">
        <Grid container spacing={3}>
          <Grid item xs={12} md={5}>
            <Typography
              variant="h6"
              gutterBottom
              sx={footerSection}
              color="secondary"
            >
              About Us
            </Typography>
            <Typography style={footerTextStyles} paragraph>
              Gulfview Holdings is a real estate investment firm focused on
              expanding a portfolio primarily in the State of Florida. Our focus
              is to continually drive exceptional performance in all areas of
              the real estate business and building long lasting, strong
              relationships through every transaction.
            </Typography>
          </Grid>
          <Grid item xs={12} md={2}>
            <Typography
              variant="h6"
              gutterBottom
              sx={footerSection}
              color="secondary"
            >
              Quick Links
            </Typography>
            <Typography style={footerTextStyles} paragraph>
              <Link href="/" style={footerLinkStyles}>
                Home
              </Link>
            </Typography>
            <Typography style={footerTextStyles} paragraph>
              <Link href="/HowItWorks" style={footerLinkStyles}>
                How it Works
              </Link>
            </Typography>
            <Typography style={footerTextStyles} paragraph>
              <Link href="/WhoWeAre" style={footerLinkStyles}>
                Who We Are
              </Link>
            </Typography>
            <Typography style={footerTextStyles} paragraph>
              <Link href="/FAQ" style={footerLinkStyles}>
                FAQ
              </Link>
            </Typography>
            <Typography style={footerTextStyles} paragraph>
              <Link href="#" style={footerLinkStyles}>
                Contact Us
              </Link>
            </Typography>
          </Grid>
          <Grid item xs={12} md={5}>
            <Typography
              variant="h6"
              gutterBottom
              sx={footerSection}
              color="secondary"
            >
              Contact Us
            </Typography>
            <Typography style={footerTextStyles} paragraph>
              Address: 7901 4th ST N STE 300, <br />
              St. Petersburg, FL 33702
            </Typography>
            <Typography style={footerTextStyles} paragraph>
              Email: Daniel@GulfViewHoldings.com
            </Typography>
            <Typography style={footerTextStyles} paragraph>
              Phone: (727) 304-3768
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </footer>
  );
};

export default Footer;
