import React from "react";
import {
  Container,
  Grid,
  Typography,
  Paper,
  Step,
  StepLabel,
  Stepper,
  Box,
} from "@mui/material";
import { Form } from "../../components/Form";
import { Header } from "../../components/header";
import money from "../../assets/step_3.png";
import contact from "../../assets/step_2.png";
import handshake from "../../assets/step_1.png";

const styles = {
  root: {
    marginTop: "2rem",
    marginBottom: "2rem",
  },
  stepContainer: {
    marginTop: "2rem",
  },
  stepBox: {
    padding: "1rem",
    borderRadius: "8px",
    marginBottom: "1rem",
    height: "25rem",
    width: "22rem",
  },
  stepCircle: {
    width: "40px",
    height: "40px",
    fontSize: "22px",
    fontWeight: 600,
    borderRadius: "50%",
    backgroundColor: "#2979ff",
    color: "#ffffff",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
};

const steps = [
  {
    title: "Tell us about your property.",
    content: "It’s quick, easy, and free!",
    image: contact,
  },
  {
    title: "If it meets our buying criteria…",
    content: "We will contact you to setup a quick appointment.",
    image: handshake,
  },
  {
    title: "We close at a local reputable title company.",
    content: "Have cash in your hands in as little as 7 days!",
    image: money,
  },
];

export const HowItWorks = () => {
  return (
    <Box>
      <Container
        maxWidth="lg"
        style={styles.root}
        disableGutters
        sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
      >
        <Typography
          variant="h3"
          fontWeight={600}
          gutterBottom
          color="secondary"
        >
          How It Works
        </Typography>
        <Grid container spacing={3} style={styles.stepContainer}>
          <Grid item xs={12} sx={{ display: "flex" }}>
            {steps.map((step, index) => (
              <Box
                style={styles.stepBox}
                sx={{
                  ...styles.stepBox,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Box style={styles.stepCircle}>
                  <Typography fontSize={"24px"}>{index + 1}</Typography>
                </Box>
                <Box component="img" src={step.image} maxHeight={300} />
                <Typography
                  color="primary"
                  sx={{
                    fontWeight: 600,
                    fontSize: "20px",
                    textAlign: "center",
                  }}
                >
                  {step.title}
                </Typography>
                <Typography sx={{ textAlign: "center" }}>
                  {step.content}
                </Typography>
              </Box>
            ))}
          </Grid>
        </Grid>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Form title={"Get a Fair Offer Today!"} />
        </Box>
      </Container>
    </Box>
  );
};
