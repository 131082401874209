import React from "react";
import { Container, Typography, Box, useTheme, Grid } from "@mui/material";
import contact from "../../assets/contact-hero.jpeg";
import CheckIcon from "@mui/icons-material/Check";
import { Form } from "../../components/Form";
const GetOfferPage: React.FC = () => {
  const { palette } = useTheme();
  return (
    <Box>
      <Box
        sx={{
          background: `url(${contact}) rgba(0, 0, 0, 0.60)`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundBlendMode: "multiply",
          height: "100%",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Grid container sx={{ display: "flex" }}>
          <Grid item xs={6}>
            <Box style={{ padding: "50px 0" }}>
              <Container
                maxWidth="md"
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <Typography
                    variant="h2"
                    color="secondary"
                    width="100%"
                    paddingBottom="10px"
                  >
                    Tell Us About Your Home
                  </Typography>
                  <Typography
                    color="white"
                    variant="h5"
                    gutterBottom
                    sx={{ fontWeight: 500 }}
                  >
                    Complete the form on this page, and we will
                    <br /> get back to you with an offer for your home!
                  </Typography>
                  <Typography
                    color="white"
                    variant="h5"
                    gutterBottom
                    sx={{ fontWeight: 500 }}
                  >
                    Working with Gulfview Holdings is:
                  </Typography>
                  <Typography
                    color="white"
                    fontSize={20}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      padding: "10px",
                      fontWeight: 600,
                    }}
                  >
                    <CheckIcon sx={{ paddingRight: "5px" }} color="secondary" />{" "}
                    Simple, Fast & Profitable
                  </Typography>
                  <Typography
                    fontSize={20}
                    color="white"
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      padding: "10px",
                      fontWeight: 600,
                    }}
                  >
                    <CheckIcon sx={{ paddingRight: "5px" }} color="secondary" />{" "}
                    Commission free
                  </Typography>
                  <Typography
                    fontSize={20}
                    color="white"
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      padding: "10px",
                      fontWeight: 600,
                    }}
                  >
                    <CheckIcon sx={{ paddingRight: "5px" }} color="secondary" />{" "}
                    Low closing costs
                  </Typography>
                  <Typography
                    fontSize={20}
                    color="white"
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      padding: "10px",
                      fontWeight: 600,
                    }}
                  >
                    <CheckIcon sx={{ paddingRight: "5px" }} color="secondary" />{" "}
                    Allow you to choose the closing date, which can be in as
                    little as 5 days
                  </Typography>
                  <Typography
                    fontSize={20}
                    color="white"
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      padding: "10px",
                      fontWeight: 600,
                    }}
                  >
                    <CheckIcon sx={{ paddingRight: "5px" }} color="secondary" />{" "}
                    Cash is wired directly into your bank account
                  </Typography>
                </Box>
              </Container>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box sx={{ padding: "20px" }}>
              <Container maxWidth="md" sx={{ backgroundColor: "transparent" }}>
                <Form
                  title={"Get Your Offer Today"}
                  titleStyles={{
                    color: palette.secondary.light,
                    filter: "drop-shadow(0px 0px 5px #000)",
                  }}
                />
              </Container>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default GetOfferPage;
