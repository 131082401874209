import React from "react";
import { Typography, Container, Box } from "@mui/material";
import { Form } from "../../components/Form";
import bg from "../../assets/whoweare.jpg";

const styles = {
  root: {
    paddingTop: "40px",
    paddingBottom: "40px",
  },
  paragraph: {
    marginBottom: "20px",
    textAlign: "left",
    fontSize: "20px",
    color: "white",
  },
  title: {
    fontWeight: "bold",
    marginBottom: "20px",
    textAlign: "center",
    color: "white",
  },
};

export const WhoWeAre: React.FC = () => {
  return (
    <Box >
      <Box
        sx={{
          background: `url(${bg}) rgba(0, 0, 0, 0.70)`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundBlendMode: "multiply",
          height: "100%",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Container maxWidth="md" sx={styles.root}>
          <Typography variant="h3" gutterBottom sx={styles.title}>
            Who We Are
          </Typography>
          <Typography variant="body1" sx={styles.paragraph}>
            Gulfview Holdings LLC is a trusted real estate solutions company
            based in Tampa, Florida. We specialize in offering innovative
            solutions for homeowners facing various property challenges,
            including foreclosure, burdensome properties, probate, or any other
            real estate issues.
          </Typography>
          <Typography variant="body1" sx={styles.paragraph}>
            Our team boasts over 30 years of combined experience in the
            industry, ensuring that every home-selling experience is quick and
            easy for our sellers. We take pride in making the process seamless,
            offering competitive offers while eliminating unnecessary fees
            such as commissions, closing costs, probate fees, and more.
          </Typography>
          <Typography variant="body1" sx={styles.paragraph}>
            We understand that every homeowner's situation is unique, which is
            why we offer flexible terms to accommodate various needs. Whether
            you need a long closing, have tenants, liens, violations, or other
            complications, Gulfview Holdings LLC strives to be extremely
            flexible in our approach to purchasing your home.
          </Typography>
          <Typography variant="body1" sx={styles.paragraph}>
            When you work with Gulfview Holdings LLC, you'll receive a free
            property analysis, valuing your property based on its age, needed
            repairs, ownership complications, and market position. Additionally,
            you'll receive a competitive and real offer, with no obligation
            to accept. Selling your Florida home has never been easier – get a
             offer from Gulfview Holdings LLC in as little as 24 hours!
          </Typography>
        </Container>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Form title="Get Your Offer Today!" />
      </Box>
    </Box>
  );
};

export default WhoWeAre;
