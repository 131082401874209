import { ThemeProvider } from "@emotion/react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Home } from "./features/home";
import { theme } from "./style/theme";
import './normalize.css';
import NavBar from "./components/NavBar";
import { WhoWeAre} from "./features/whoweare";
import ContactPage from "./features/contact";
import { HowItWorks } from "./features/howitworks";
import Footer from "./components/footer";
import { FAQ } from "./features/faq";

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <NavBar />
      <Router>
        <Routes>
          <Route index path="/" element={<Home />}/>
          <Route index path="/WhoWeAre" element={<WhoWeAre />}/>
          <Route index path="/Contact" element={<ContactPage />}/>
          <Route index path="/HowItWorks" element={<HowItWorks />}/>
          <Route index path="/FAQ" element={<FAQ/>}/>
        </Routes>
      </Router>
      <Footer/>
    </ThemeProvider>
  );
};

export default App;
