import {
  Toolbar,
  Button,
  useTheme,
  AppBar,
  Box,
  Typography,
  Grid,
  useMediaQuery,
} from "@mui/material";
import logo from "../../assets/logo.svg";

const NavBar = () => {
  const { palette } = useTheme();
  const isSm = useMediaQuery((theme: any) => theme.breakpoints.down("lg"));

  return (
    <AppBar position="static">
      <Toolbar
        sx={{
          backgroundColor: palette.primary.main,
        }}
      >
        <Grid container sx={{ display: "flex", alignContent: "center" }}>
          <Grid
            item
            xs={3}
            sx={{ display: "flex", justifyContent: "flex-start" }}
          >
            <Box
              component="img"
              src={logo}
              sx={{ objectFit: "fill", height: "180px" }}
            />
          </Grid>
          <Grid
            item
            xs={isSm ? 9 : 6}
            sx={{ display: "flex", justifyContent: "center" }}
          >
            <Button
              disableRipple
              href="/"
              sx={{ color: palette.secondary.light, fontSize: 20 }}
            >
              Home
            </Button>
            <Button
              disableRipple
              href="/HowItWorks"
              sx={{ color: palette.secondary.light, fontSize: 20 }}
            >
              How it Works
            </Button>
            <Button
              href="/WhoWeAre"
              disableRipple
              sx={{ color: palette.secondary.light, fontSize: 20 }}
            >
              Who We Are
            </Button>
            <Button
              disableRipple
              href="/FAQ"
              sx={{ color: palette.secondary.light, fontSize: 20 }}
            >
              FAQ
            </Button>
            <Button
              disableRipple
              href="/Contact"
              sx={{ color: palette.secondary.light, fontSize: 20 }}
            >
              Contact Us
            </Button>
          </Grid>
          <Grid
            item
            xs={3}
            sx={{
              justifyContent: "flex-end",
              alignItems: "center",
              display: isSm ? "none" : "flex",
            }}
          >
            <Typography
              sx={{
                color: palette.secondary.main,
                fontWeight: "700",
                textAlign: "center",
              }}
              variant="h5"
            >
              Call Or Text! <br /> (727) 304-3768
            </Typography>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
};

export default NavBar;
