import { Container, Grid, Paper, Typography, useTheme } from "@mui/material";
import { Box } from "@mui/system";
import bg from "../../assets/bg.jpg";
import Clock from "../../assets/24-hours.svg";
import Calendar from "../../assets/calendar.svg";
import Closing from "../../assets/closing-open.svg";
import { Form } from "../../components/Form";

export const Home = () => {
  const { palette } = useTheme();
  return (
    <Box>
      <Box
        sx={{
          background: `url(${bg}) rgba(0, 0, 0, 0.35)`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundBlendMode: "multiply",
          minHeight: 400,
          maxHeight: 500,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Form
          title={"No Agents. No Fees. No Commisions."}
          titleStyles={{
            color: palette.secondary.light,
            filter: "drop-shadow(0px 0px 5px #000)",
          }}
        />
      </Box>
      <Paper
        elevation={3}
        sx={{
          backgroundPosition: "center center",
          display: "flex",
          justifyContent: "center",
          backgroundColor: palette.secondary.light,
          color: palette.primary.dark,
        }}
      ></Paper>
      <Box>
        <Paper
          elevation={1}
          sx={{ backgroundColor: palette.secondary.light, padding: 5 }}
        >
          <Grid container>
            <Grid xs={12}>
              <Container
                sx={{
                  alignItems: "center",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Typography variant="h4" gutterBottom>
                  The Easiest Way To Sell Your House Fast In Tampa
                </Typography>
                <Typography
                  paragraph
                  sx={{ width: "80%", padding: 5, textAlign: "center" }}
                  fontSize={22}
                >
                  At Gulfview Holdings LLC, we are dedicatied to ensuring a
                  swift, seamless, and stress free experience for home sellers
                  in Tampa.
                  <strong>
                    What sets us apart is our direct purchase of houses,
                    accepting them As-Is
                  </strong>
                  . Forget about making repairs, we'll handle the cleaning and
                  maintenance. If you've thought about selling your home, we're
                  prepared to present you with an offer. Your selling process
                  becomes effortlessly convenient with us.
                </Typography>
              </Container>
            </Grid>
            <Grid xs={12}>
              <Container
                sx={{
                  alignItems: "center",
                  display: "flex",
                }}
              >
                <Grid container spacing={3}>
                  <Grid
                    item
                    xs={12}
                    md={4}
                    sx={{
                      alignItems: "center",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <Box component="img" src={Clock} sx={{ height: "50px" }} />
                    <Typography
                      gutterBottom
                      sx={{ fontSize: 28, paddingTop: 3, fontWeight: 500 }}
                    >
                      Step 1
                    </Typography>
                    <Typography paragraph fontSize={22}>
                      Get a fast and fair offer
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={4}
                    sx={{
                      alignItems: "center",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <Box
                      component="img"
                      src={Calendar}
                      sx={{ height: "50px" }}
                    />

                    <Typography
                      gutterBottom
                      sx={{ fontSize: 28, paddingTop: 3, fontWeight: 500 }}
                    >
                      Step 2
                    </Typography>
                    <Typography paragraph fontSize={22}>
                      Pick the date you want to close
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={4}
                    sx={{
                      alignItems: "center",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <Box
                      component="img"
                      src={Closing}
                      sx={{ height: "50px" }}
                    />
                    <Typography
                      gutterBottom
                      sx={{ fontSize: 28, paddingTop: 3, fontWeight: 500 }}
                    >
                      Step 3
                    </Typography>
                    <Typography fontSize={22} paragraph>
                      Sell and close quickly
                    </Typography>
                  </Grid>
                </Grid>
              </Container>
            </Grid>
          </Grid>
        </Paper>
      </Box>
    </Box>
  );
};
